<!--
 * @Author: DY
 * @Date: 2021-03-11 15:34:38
 * @LastEditTime: 2021-06-21 10:27:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\salesBusiness\priceSetting\InfoPriceCombinationSelection.vue
-->
<template>
    <div class="InfoPriceCombinationSelection">
        <div class="previewArea">
            <h4 class="iconfont iconxianshi">
                预览区
            </h4>
            <p>{{ smallclass_name }}</p>
        </div>
        <div class="settingArea">
            <h4 class="iconfont iconshezhi1">
                设置区
            </h4>
            <span>调整顺序</span>
            <el-table
                border
                :data="tableData"
                height="calc(100% - 1.1rem)"
                style="width: 100%"
            >
                <el-table-column label="序号">
                    <template slot-scope="scope">
                        <p class="serialNumber">
                            {{ scope.$index + 1 }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="subclass_name"
                    label="子类名称"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="smallclass_name"
                    label="小类名称"
                >
                </el-table-column>
                <el-table-column
                    prop="attr_price"
                    label="价格（元）"
                >
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="操作"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="下移"
                            placement="top"
                            v-if="scope.$index != tableData.length-1"
                        >
                            <el-button
                                type="warning"
                                size="small"
                                plain
                                class="el-icon-sort-down"
                                @click="onDownItem(scope.row, scope.$index)"
                            ></el-button>
                        </el-tooltip>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="上移"
                            placement="top"
                            v-if="scope.$index != 0"
                        >
                            <el-button
                                type="primary"
                                size="small"
                                plain
                                class="el-icon-sort-up"
                                @click="onUpItem(scope.row, scope.$index)"
                            ></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <div class="separatorSettings">
                设置分割符：
                <el-input
                    placeholder="请在此处输入分割符号，例如：+"
                    v-model="separator"
                >
                </el-input>
                <el-button type="primary" @click="confirmSeparatorSettings"></el-button>
            </div>
        </div>
        <footer>
            <el-button @click="Close">
                取消
            </el-button>
            <el-button type="primary" @click="confirmSelection">
                确认
            </el-button>
        </footer>
    </div>
</template>

<script>
export default {
    // 信息价组合选择弹出
    name: 'info-price-combination-selection',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            smallclass_name: '',
            smallclass_code: '',
            additional_model_price: 0,
            tableData: [],
            // 分隔符
            separator: '',
        };
    },
    computed: {},
    watch: {},
    async created() {
        this.tableData = this.extr.selectData;
        // 获取分隔符数据
        await this.getSeparator();
        this.processingData();
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 取消添加关闭弹出层
        Close() {
            this.$parent.hide();
        },
        // 确认修改分隔符
        async confirmSeparatorSettings() {
            this.processingData();
            await this.seveSeparator();
        },

        processingData() {
            this.smallclass_name = '';
            this.smallclass_code = '';
            this.additional_model_price = 0;
            this.tableData.forEach(item => {
                this.smallclass_name += item.smallclass_name + this.separator;
                this.smallclass_code += item.smallclass_code + ',';
                this.additional_model_price += Number(item.attr_price);
            });
            this.smallclass_name = this.smallclass_name.substring(0, this.smallclass_name.lastIndexOf(this.separator));
            this.smallclass_code = this.smallclass_code.substring(0, this.smallclass_code.lastIndexOf(','));
        },
        // 上移
        onUpItem(item, index) {
            this.tableData.splice(index - 1, 0, item);
            this.tableData.splice(index + 1, 1);
            this.processingData();
        },
        // 下移
        onDownItem(item, index) {
            this.tableData.splice(index + 2, 0, item);
            this.tableData.splice(index, 1);
            this.processingData();
        },
        // 确认
        confirmSelection() {
            const callbackData = {
                id: this.tableData[0].id,
                sort_num: this.tableData[0].sort_num,
                use_count: this.tableData[0].use_count,
                smallclass_name: this.smallclass_name,
                smallclass_code: this.smallclass_code,
                additional_model_price: this.additional_model_price,
            };
            this.extr.sureCallback(callbackData);
            this.$parent.hide();
        },

        // 获取分隔符数据
        async getSeparator() {
            await this.$axios
                .get('/interfaceApi/sale/features_manage/query_value/additional_decollator')
                .then(res => {
                    this.separator = res;
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存分隔符数据
        async seveSeparator() {
            await this.$axios
                .put('/interfaceApi/sale/features_manage/modify_value', {
                    features_code: 'additional_decollator',
                    features_value: this.separator,
                })
                .then(res => {
                    if (res) {
                        this.$message({
                            message: '分隔符修改成功！',
                            type: 'success',
                        });
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>
<style lang="stylus">
.InfoPriceCombinationSelection
    height 100%
    padding 0.4rem
    position relative
    h4
        font-size 0.14rem
        color #29638D
        margin-bottom 0.14rem
    .previewArea
        padding 0.1rem 0.2rem
        background #F6FAFF
        border 1px solid #D1E2F7
        box-shadow 0px 2px 4px 0px rgba(102, 149, 184, 0.2)
        margin-bottom 0.2rem
        p
            font-size 0.16rem
            color #333333
    .settingArea
        padding 0.2rem
        height 70%
        background #FFFFFF
        border 1px solid #D1E2F7
        box-shadow 0px 2px 4px 0px rgba(90, 141, 179, 0.2)
        >span
            color #666666
            font-size 0.14rem
            margin-bottom 0.1rem
            display block
        .el-table
            th,td
                text-align center
                padding 0.07rem 0
                border-right none
            .serialNumber
                display inline-block
                width 0.24rem
                height 0.24rem
                font-size 0.14rem
                line-height 0.24rem
                text-align center
                color #5A8DB3
                background url(images/InfoPriceCombinationSelection/icon_1.png) no-repeat
                background-size 100% 100%
        .separatorSettings
            height 0.4rem
            margin-top 0.1rem
            display flex
            line-height 0.4rem
            .el-input
                width 2.88rem
                height 0.38rem
                line-height 0.38rem
                margin-right 0.2rem
            .el-button
                width 0.38rem
                height 0.38rem
                border none
                background url(images/InfoPriceCombinationSelection/btn_1.png) no-repeat
                background-size 100% 100%
    footer
        width 100%
        height 0.4rem
        position absolute
        bottom 0.4rem
        left 0.4rem
        text-align center
        .el-button
            width 2.2rem
            height 0.4rem
            margin 0 0.3rem
</style>
